import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerThunk } from "../store/thunks/authThunk";
import ReCAPTCHA from "react-google-recaptcha";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";
import LoadingScreen from "../components/LoadingScreen";

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function RegisterPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error } = useSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);

    let missingParts = [];

    if (pwd.length < 8) {
      missingParts.push("at least 8 characters");
    }
    if (!/[A-Z]/.test(pwd)) {
      missingParts.push("an uppercase letter");
    }
    if (!/\d/.test(pwd)) {
      missingParts.push("a number");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) {
      missingParts.push("a special character");
    }

    if (missingParts.length === 0) {
      setPasswordStrength("Strong");
    } else if (missingParts.length <= 2) {
      setPasswordStrength(`Medium, missing: ${missingParts.join(", ")}`);
    } else {
      setPasswordStrength(`Weak, missing: ${missingParts.join(", ")}`);
    }
  };

  const getPasswordStrengthColor = (strength) => {
    if (strength.includes("Weak")) {
      return "text-red-600";
    } else if (strength.includes("Medium")) {
      return "text-yellow-500";
    } else if (strength.includes("Strong")) {
      return "text-green-500";
    } else {
      return "";
    }
  };

  const handleCreateAccountClick = (event) => {
    event.preventDefault();
    setSubmitError("");

    if (!captchaVerified) {
      setSubmitError("Please complete the CAPTCHA");
      return;
    }

    dispatch(
      registerThunk({
        firstName,
        lastName,
        username,
        password,
        recaptchaToken: captchaToken,
      })
    )
      .unwrap()
      .then(() => {
        navigate("/loading", {
          state: {
            message: "Creating account...",
            duration: 5000,
            redirectTo: "/login",
          },
        });
      })
      .catch((err) => {
        const errorMessage =
          typeof err === "object" && err.message
            ? err.message
            : "Registration failed. Please try again.";
        setSubmitError(errorMessage);
      });
  };

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
    setCaptchaToken(value);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div>
          <img
            className="mx-auto h-12 w-auto cursor-pointer"
            src={logo}
            alt="Synergy Real Estate Logo"
            width={48}
            height={48}
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-[#0A2342]">
            Create an account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleCreateAccountClick}>
          <div className="space-y-4">
            <div>
              <label htmlFor="firstName" className="sr-only">
                First Name
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="lastName" className="sr-only">
                Last Name
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type={passwordVisible ? "text" : "password"}
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={togglePasswordVisibility}>
                {passwordVisible ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400" />
                )}
              </div>
            </div>
          </div>

          {password && (
            <p
              className={`mt-2 text-sm ${getPasswordStrengthColor(
                passwordStrength
              )}`}>
              Password strength: {passwordStrength}
            </p>
          )}

          <div className="flex justify-center">
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              onChange={onCaptchaChange}
              size="normal"
            />
          </div>

          <div>
            <motion.button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342]"
              disabled={!captchaVerified}
              whileHover={{ scale: 1.05 }}
              whil
              eTap={{ scale: 0.95 }}>
              Create Account
            </motion.button>
          </div>
        </form>

        {submitError && (
          <p className="mt-2 text-center text-sm text-red-600">{submitError}</p>
        )}
        {error && (
          <p className="mt-2 text-center text-sm text-red-600">
            {typeof error === "string" ? error : error.message}
          </p>
        )}

        <div className="text-center">
          <p className="mt-2 text-sm text-gray-600">
            Already have an account?{" "}
            <motion.button
              onClick={() => navigate("/login")}
              className="font-medium text-[#0A2342] hover:text-[#1C4B82]"
              whileHover={{ scale: 1.05 }}>
              Sign in
            </motion.button>
          </p>
        </div>
      </motion.div>
    </div>
  );
}
