import React from "react";

export default function ProgressBar({ topText, bottomTexts = [], progress }) {
  return (
    <div>
      <p className="text-sm font-medium text-[#0A2342] text-center">
        {topText}
      </p>
      <div aria-hidden="true" className="mt-4">
        <div className="overflow-hidden bg-gray-200">
          <div
            style={{
              width: `${progress}%`,
              transition: "width 0.5s ease-in-out",
            }}
            className="h-2 bg-[#0A2342]"
          />
        </div>
        <div className="mt-4 grid grid-cols-4 text-sm font-medium text-gray-600">
          {bottomTexts.map((text, index) => (
            <div
              key={index}
              className={
                index === 1 ? "text-center" : index === 3 ? "text-right" : ""
              }>
              <span
                className={index === 0 || index === 1 ? "text-[#0A2342]" : ""}>
                {text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
