import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import dataReducer from "./slices/dataSlice";
import authReducer from "./slices/authSlice";
import onboardingReducer from "./slices/onboardingSlice";
import pdfsReducer from "./slices/pdfsSlice";
import repliersReducer from "./slices/repliersSlice";

const persistConfig = {
  key: "propulsionai",
  storage: storageSession,
  whitelist: ["auth", "userPreferences"], // Whitelist necessary slices if any
};

const rootReducer = combineReducers({
  data: dataReducer,
  auth: authReducer,
  onboarding: onboardingReducer,
  pdfs: pdfsReducer,
  repliers: repliersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/dataThunk";
export * from "./thunks/authThunk";
export * from "./thunks/onboardingThunk";
export * from "./thunks/pdfsThunk";
export * from "./thunks/repliersThunk";
