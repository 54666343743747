import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

// Fetch onboarding data thunk
export const fetchOnboarding = createAsyncThunk(
  "onboarding/fetch",
  async ({ token, userId }, { getState, rejectWithValue }) => {
    try {
      const config = generateConfig(token);
      const response = await api.get(
        `/api/onboarding?userId=${userId}`,
        config
      );

      console.log("fetchonboaridng response.data", response.data);
      return response.data; // Assuming the response contains the onboarding data
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch onboarding data"
      );
    }
  }
);

// submitOnboarding thunk
export const submitOnboarding = createAsyncThunk(
  "onboarding/submit",
  async (onboardingData, { getState, rejectWithValue }) => {
    try {
      const { token, userId } = getState().auth;
      const config = generateConfig(token);
      const response = await api.post(
        "/api/onboarding",
        { ...onboardingData, userId },
        config
      );
      return response.data;
    } catch (error) {
      if (error.response?.data?.message === "Onboarding already completed") {
        return rejectWithValue({
          message: error.response.data.message,
          type: "alreadyCompleted",
          existingOnboarding: error.response.data.existingOnboarding,
        });
      }
      return rejectWithValue(
        error.response?.data || { message: "Onboarding submission failed" }
      );
    }
  }
);

// updateOnboarding thunk using PATCH
export const updateOnboarding = createAsyncThunk(
  "onboarding/update",
  async (onboardingData, { getState, rejectWithValue }) => {
    try {
      const { token, userId } = getState().auth;
      const config = generateConfig(token);

      // Create a new object with only defined fields
      const filteredData = {};
      const requiredFields = [
        "motivation",
        "location",
        "propertyType",
        "bedrooms",
        "bathrooms",
        "priceRange",
        "ranking",
        "topAmenities",
        "buyTimeline",
      ];
      requiredFields.forEach((field) => {
        if (onboardingData[field] !== undefined) {
          filteredData[field] = onboardingData[field];
        }
      });

      const response = await api.patch(
        "/api/onboarding",
        { ...filteredData, userId }, // Send filtered data
        config
      );
      return response.data; // Return the updated data
    } catch (error) {
      if (error.response?.data?.message === "Onboarding not found") {
        return rejectWithValue({
          message: error.response.data.message,
          type: "notFound",
        });
      }
      return rejectWithValue(
        error.response?.data || { message: "Onboarding update failed" }
      );
    }
  }
);
