import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitOnboarding } from "../store/thunks/onboardingThunk";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { motion } from "framer-motion";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import ProgressBar from "../components/ProgressBar";
import OnboardingQuestions from "../components/onboardingQuestions";

export default function OnboardingPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [submitError, setSubmitError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [existingOnboarding, setExistingOnboarding] = useState([]);
  const [hasStarted, setHasStarted] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const handleSkipOnboarding = () => {
    navigate("/dashboard");
  };

  const totalSteps = OnboardingQuestions.length;
  const progress = ((currentStep + 1) / totalSteps) * 100;

  const isCurrentAnswerValid = () => {
    const currentQuestion = OnboardingQuestions[currentStep];
    const answer = answers[currentQuestion.key];
    if (!answer || (Array.isArray(answer) && answer.length === 0)) {
      setValidationError(`Question requires an answer.`);
      return false;
    }
    setValidationError("");
    return true;
  };

  const handleNext = () => {
    if (!isCurrentAnswerValid()) return;
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAnswerChange = (e) => {
    const { type, key } = OnboardingQuestions[currentStep];

    if (type === "multi-select") {
      const value = e.target.value;
      const selected = answers[key] || [];
      if (selected.includes(value)) {
        setAnswers({
          ...answers,
          [key]: selected.filter((item) => item !== value),
        });
      } else if (selected.length < 3) {
        setAnswers({
          ...answers,
          [key]: [...selected, value],
        });
      }
    } else if (type === "text" && key === "location") {
      // Use the input value and split it by commas, allowing for spaces
      const locations = e.target.value.split(",");
      setAnswers({
        ...answers,
        [key]: locations,
      });
    } else {
      setAnswers({
        ...answers,
        [key]: e.target.value,
      });
    }
  };

  const handleRankingChange = (result) => {
    if (!result.destination) return;

    const items = Array.from(answers.ranking || currentQuestion.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      ranking: items,
    }));
  };

  const handleSubmit = async () => {
    for (const question of OnboardingQuestions) {
      const answer = answers[question.key];
      if (!answer || (Array.isArray(answer) && answer.length === 0)) {
        setValidationError(
          `Please complete the question: "${question.question}"`
        );
        return;
      }
    }
    setSubmitError("");
    try {
      await dispatch(submitOnboarding(answers)).unwrap();
      navigate("/loading", {
        state: {
          message: "Onboarding saved.",
          message2: "Creating your custom dashboard...",
          duration: 5000,
          redirectTo: "/dashboard",
        },
      });
    } catch (error) {
      setSubmitError("Onboarding already completed. Please skip.");
    }
  };

  const currentQuestion = OnboardingQuestions[currentStep];
  const { type } = currentQuestion;

  const onboardingTexts = OnboardingQuestions.map((q) => q.name);

  useEffect(() => {
    if (type === "ranking" && !answers.ranking) {
      setAnswers((prev) => ({ ...prev, ranking: currentQuestion.options }));
    }
  }, [currentStep, type, answers.ranking, currentQuestion.options]);

  if (!hasStarted) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-center">
        <h1 className="text-3xl font-bold text-[#0A2342] mb-8">
          Welcome to Synergy AI, {user.firstName} {user.lastName}
        </h1>
        <p className="text-lg text-gray-700 mb-8">
          Complete the onboarding questionnaire to customize your results. You
          can skip and return at any time.
        </p>
        <p className="text-lg text-gray-700 mb-8">
          Onboarding can be edited after completion.
        </p>
        <motion.button
          onClick={() => setHasStarted(true)}
          className="bg-[#0A2342] text-white py-2 px-6"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}>
          Begin
        </motion.button>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Skip onboarding button */}
      <motion.button
        onClick={handleSkipOnboarding}
        className="absolute top-4 right-4 bg-gray-200 text-[#0A2342] py-2 px-4 rounded"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}>
        Skip onboarding
      </motion.button>

      {/* Onboarding questions */}
      <motion.div
        className="w-full max-w-2xl bg-white p-8 shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <ProgressBar
          topText={`Step ${currentStep + 1} of ${totalSteps}: ${
            onboardingTexts[currentStep]
          }`}
          progress={progress}
        />

        <h2 className="text-2xl font-bold mb-6 text-center text-[#0A2342]">
          {currentQuestion.question}
        </h2>
        <div className="space-y-4">
          {type === "ranking" && (
            <DragDropContext onDragEnd={handleRankingChange}>
              <Droppable droppableId="ranking">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-2 bg-white p-4 rounded-lg border border-gray-300">
                    {(answers.ranking || currentQuestion.options).map(
                      (option, index) => (
                        <Draggable
                          key={option}
                          draggableId={option}
                          index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`p-3 bg-gray-100 border border-gray-300 text-[#0A2342] flex items-center justify-between rounded-md ${
                                snapshot.isDragging ? "shadow-lg" : ""
                              }`}>
                              <span>{option}</span>
                              <EllipsisVerticalIcon className="h-5 w-5 text-gray-400" />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {type === "multi-select" && (
            <div className="space-y-4">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name={currentQuestion.key}
                    value={option}
                    onChange={handleAnswerChange}
                    checked={
                      answers[currentQuestion.key] &&
                      answers[currentQuestion.key].includes(option)
                    }
                    disabled={
                      answers[currentQuestion.key] &&
                      answers[currentQuestion.key].length >= 3 &&
                      !answers[currentQuestion.key].includes(option)
                    }
                    className="form-checkbox h-5 w-5 text-[#0A2342] accent-[#0A2342]"
                  />
                  <span className="text-[#0A2342]">{option}</span>
                </label>
              ))}
            </div>
          )}

          {type === "text" && (
            <div className="space-y-4">
              <input
                type="text"
                name={currentQuestion.key}
                value={answers[currentQuestion.key] || ""}
                onChange={handleAnswerChange}
                className="form-input w-full border border-[#0A2342] p-2 px-3 text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342]"
                placeholder="Separate multiple areas with commas"
              />
            </div>
          )}

          {!type && (
            <div className="space-y-4">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="flex items-center space-x-3">
                  <input
                    type="radio"
                    name={currentQuestion.key}
                    value={option}
                    onChange={handleAnswerChange}
                    checked={answers[currentQuestion.key] === option}
                    className="form-radio h-5 w-5 text-[#0A2342] accent-[#0A2342]"
                  />
                  <span className="text-[#0A2342]">{option}</span>
                </label>
              ))}
            </div>
          )}
        </div>

        {validationError && (
          <p className="text-red-600 text-sm text-center mt-4">
            {validationError}
          </p>
        )}

        {/* Navigation buttons */}
        <div className="flex justify-between mt-8">
          <motion.button
            onClick={handleBack}
            className="bg-gray-300 text-[#0A2342] py-2 px-6 disabled:opacity-50"
            disabled={currentStep === 0}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}>
            Back
          </motion.button>
          {currentStep < OnboardingQuestions.length - 1 ? (
            <motion.button
              onClick={handleNext}
              className="bg-[#0A2342] text-white py-2 px-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Next
            </motion.button>
          ) : (
            <motion.button
              onClick={handleSubmit}
              className="bg-[#0A2342] text-white py-2 px-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Submit Onboarding
            </motion.button>
          )}
        </div>

        {/* Submit error */}
        {submitError && (
          <div className="text-center">
            <p className="text-red-600 text-sm text-center mt-4">
              {submitError}
            </p>
            <button
              onClick={handleSkipOnboarding}
              className="bg-gray-200 text-[#0A2342] py-2 px-4 mt-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Skip onboarding
            </button>
          </div>
        )}

        {/* Submit success */}
        {submitSuccess && (
          <div className="text-green-600 text-center mt-6">
            <p className="text-lg font-semibold">
              Onboarding completed successfully!
            </p>
            <motion.button
              onClick={() => navigate("/dashboard")}
              className="mt-4 bg-[#0A2342] text-white py-2 px-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Navigate to Dashboard
            </motion.button>
          </div>
        )}
      </motion.div>
    </div>
  );
}
