import React from "react";

const TaxTable = ({ taxes }) => (
  <div className="pt-4">
    <h3 className="text-lg font-semibold text-[#0A2342] mb-2">
      Tax Information
    </h3>
    <table className="w-full text-left">
      <thead>
        <tr>
          <th className="border-b-2 border-gray-300 py-2">Assessment Year</th>
          <th className="border-b-2 border-gray-300 py-2">Annual Amount</th>
        </tr>
      </thead>
      <tbody>
        {taxes ? (
          <tr>
            <td className="border-b border-gray-300 py-2">
              {taxes.assessmentYear}
            </td>
            <td className="border-b border-gray-300 py-2">
              {taxes.annualAmount
                ? `$${parseFloat(taxes.annualAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                : "N/A"}
            </td>
          </tr>
        ) : (
          <tr>
            <td className="py-2" colSpan="2">
              No tax information available.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default TaxTable;
