import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { searchPropertyThunk } from "../../store/thunks/repliersThunk";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const SearchBar = () => {
  const [input, setInput] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const searchBarRef = useRef(null);

  const handleSearch = (type) => {
    if (!input.trim()) return;

    console.log(`${type}: ${input}`);
    if (type === "address" || type === "mls") {
      dispatch(searchPropertyThunk(input));
    }

    setShowDropdown(false); // Hide dropdown after selection
    setInput(""); // Clear input after search (optional)
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch("address");
    }
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={searchBarRef}
      className="relative bg-[#f5f5f5] border border-[#0A2342] shadow-md flex items-center w-full max-w-md mx-auto">
      <input
        type="text"
        placeholder="Enter address, street, or MLS number"
        className="flex-grow px-3 py-1 text-[#0A2342] focus:outline-none"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        onFocus={handleInputFocus}
      />
      <button
        className="p-2 bg-[#0A2342] text-white"
        onClick={() => handleSearch("address")}>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <MagnifyingGlassIcon className="h-5 w-5" />
        </motion.div>
      </button>

      {/* Dropdown Menu */}
      {showDropdown && (
        <div className="absolute top-full left-0 w-full bg-white border border-[#0A2342] shadow-md z-10">
          <div
            onClick={() => handleSearch("address")}
            className="cursor-pointer px-4 py-2 hover:bg-gray-200">
            Address: {input}
          </div>
          <div
            onClick={() => handleSearch("mls")}
            className="cursor-pointer px-4 py-2 hover:bg-gray-200">
            MLS: {input}
          </div>
          <div
            onClick={() => handleSearch("city")}
            className="cursor-pointer px-4 py-2 hover:bg-gray-200">
            City: {input}
          </div>
          <div
            onClick={() => handleSearch("neighbourhood")}
            className="cursor-pointer px-4 py-2 hover:bg-gray-200">
            Neighbourhood: {input}
          </div>
          <div
            onClick={() => handleSearch("nlp")}
            className="cursor-pointer px-4 py-2 hover:bg-gray-200">
            NLP: {input}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
