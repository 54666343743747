import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const generateConfig = (token = null) => {
  const headers = {
    "Content-Type": "application/json",
    "X-API-Key": SECRET_KEY,
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return {
    baseURL: API_BASE_URL,
    headers,
  };
};

export const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  config.headers["X-API-Key"] = SECRET_KEY;
  return config;
});
