import React, { useCallback, useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const MortgageCalculator = ({ property, setMonthlyPayment }) => {
  const [mortgageDetails, setMortgageDetails] = useState({
    dollarDownPayment: "",
    percentageDownPayment: "",
    interestRate: "",
    mortgageTerm: "25",
    monthlyPayment: 0,
  });

  const calculateMortgage = useCallback(() => {
    const homePrice = property?.listPrice || 0;
    const downPaymentAmount = mortgageDetails.dollarDownPayment
      ? parseFloat(mortgageDetails.dollarDownPayment.replace(/[$,]/g, "")) || 0
      : mortgageDetails.percentageDownPayment
      ? homePrice * (parseFloat(mortgageDetails.percentageDownPayment) / 100)
      : 0;

    const mortgageAmount = homePrice - downPaymentAmount;
    const monthlyInterestRate = mortgageDetails.interestRate / 100 / 12;
    const totalPayments = mortgageDetails.mortgageTerm * 12;

    if (mortgageAmount && monthlyInterestRate && totalPayments) {
      const monthlyPayment =
        (mortgageAmount *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, totalPayments)) /
        (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

      const formattedMonthlyPayment = monthlyPayment
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setMortgageDetails((prev) => ({
        ...prev,
        monthlyPayment: formattedMonthlyPayment,
      }));

      // Set the monthly payment in the parent component as a number
      setMonthlyPayment(monthlyPayment); // Pass the number directly
    }
  }, [
    property?.listPrice,
    mortgageDetails.dollarDownPayment,
    mortgageDetails.percentageDownPayment,
    mortgageDetails.interestRate,
    mortgageDetails.mortgageTerm,
    setMonthlyPayment,
  ]);

  useEffect(() => {
    calculateMortgage();
  }, [calculateMortgage]);

  const handleMortgageInputChange = (e) => {
    const { id, value } = e.target;
    setMortgageDetails((prev) => ({
      ...prev,
      [id]: value === "" ? "" : value,
    }));
  };

  const resetMortgageFields = () => {
    setMortgageDetails({
      dollarDownPayment: "",
      percentageDownPayment: "",
      interestRate: "",
      mortgageTerm: "25",
      monthlyPayment: 0,
    });
  };

  const pieData = [
    {
      name: "Down Payment",
      value: mortgageDetails.dollarDownPayment
        ? parseFloat(mortgageDetails.dollarDownPayment.replace(/[$,]/g, ""))
        : mortgageDetails.percentageDownPayment
        ? property.listPrice *
          (parseFloat(mortgageDetails.percentageDownPayment) / 100)
        : 0,
    },
    {
      name: "Mortgage Amount",
      value:
        property.listPrice -
          (mortgageDetails.dollarDownPayment
            ? parseFloat(
                mortgageDetails.dollarDownPayment.replace(/[$,]/g, "")
              ) || 0
            : mortgageDetails.percentageDownPayment
            ? property.listPrice *
              (parseFloat(mortgageDetails.percentageDownPayment) / 100)
            : 0) || 0,
    },
  ];

  const COLORS = ["#0A2342", "#E5B13A"];

  return (
    <div className="pt-4">
      <h3 className="text-lg font-semibold text-[#0A2342]">
        Mortgage Calculator
      </h3>
      <p className="block text-sm font-medium text-gray-700 pb-2">
        All values are in CAD
      </p>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 pr-4 space-y-4">
          <div>
            <label
              htmlFor="homePrice"
              className="block text-sm font-medium text-gray-700">
              Home Price
            </label>
            <input
              type="text"
              id="homePrice"
              className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
              value={`$${Math.round(property.listPrice || 0).toLocaleString()}`}
              readOnly
            />
          </div>

          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full">
              <label
                htmlFor="dollarDownPayment"
                className="block text-sm font-medium text-gray-700">
                Down Payment ($)
              </label>
              <input
                type="text"
                id="dollarDownPayment"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                value={
                  mortgageDetails.dollarDownPayment
                    ? `$${mortgageDetails.dollarDownPayment.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}`
                    : ""
                }
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers
                  handleMortgageInputChange({
                    target: { id: e.target.id, value },
                  });
                  if (value) {
                    setMortgageDetails((prev) => ({
                      ...prev,
                      percentageDownPayment: "",
                    }));
                  }
                }}
                disabled={!!mortgageDetails.percentageDownPayment}
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="percentageDownPayment"
                className="block text-sm font-medium text-gray-700">
                Down Payment (%)
              </label>
              <input
                type="text"
                id="percentageDownPayment"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                value={
                  mortgageDetails.percentageDownPayment
                    ? `${mortgageDetails.percentageDownPayment}%`
                    : ""
                }
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers
                  handleMortgageInputChange({
                    target: { id: e.target.id, value },
                  });
                  if (value) {
                    setMortgageDetails((prev) => ({
                      ...prev,
                      dollarDownPayment: "",
                    }));
                  }
                }}
                disabled={!!mortgageDetails.dollarDownPayment}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full">
              <label
                htmlFor="interestRate"
                className="block text-sm font-medium text-gray-700">
                Annual Interest Rate (%)
              </label>
              <input
                type="number"
                id="interestRate"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                step="0.01"
                value={mortgageDetails.interestRate}
                onChange={handleMortgageInputChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="mortgageTerm"
                className="block text-sm font-medium text-gray-700">
                Mortgage Term (years)
              </label>
              <input
                type="number"
                id="mortgageTerm"
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none text-lg font-medium"
                value={mortgageDetails.mortgageTerm}
                onChange={handleMortgageInputChange}
              />
            </div>
          </div>

          <button
            onClick={resetMortgageFields}
            className="bg-[#0A2342] text-white py-2 px-4 mt-4 hover:bg-opacity-90 transition-colors duration-200">
            Reset Fields
          </button>
        </div>

        <div className="w-full md:w-1/2 pl-4 flex flex-col justify-center items-center">
          <div className="text-center mb-4">
            <span className="text-lg font-semibold text-[#0A2342]">
              Monthly Payment:{" "}
            </span>
            <span className="text-2xl font-semibold text-[#0A2342]">
              ${mortgageDetails.monthlyPayment}
            </span>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value">
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) =>
                  `$${new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(value)}`
                }
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;
