// PropertiesListView.js

import React, { useState } from "react";
import { motion } from "framer-motion";
import PropertyGridTile from "../properties/PropertyGridTile";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const PropertiesListView = ({ title, properties, description }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const propertiesArray = Array.isArray(properties) ? properties : [];

  // Show only the first 10 properties
  const displayedProperties = propertiesArray.slice(0, 10);

  const handleClick = (event, mlsNumber, boardId) => {
    const url = `/propertydetails/${mlsNumber}/${boardId}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  return (
    <motion.section
      className="my-8 flex flex-col h-full px-4"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* {title && (
        <div className="flex items-center mb-4 pl-4 relative">
          <h2 className="text-xl sm:text-2xl font-bold text-[#0A2342] flex items-center">
            {title}
            {description && (
              <button
                className="text-blue-500 ml-2"
                onClick={() => setShowTooltip(!showTooltip)}>
                <InformationCircleIcon className="w-5 h-5 text-[#0A2342]" />
              </button>
            )}
          </h2>
          {showTooltip && (
            <div className="text-sm absolute bottom-full mb-1 w-80 bg-white border border-gray-300 p-2 text-[#0A2342] shadow-lg z-10">
              {description}
            </div>
          )}
        </div>
      )} */}

      <div
        className="container mx-auto flex-grow overflow-y-auto md:overflow-y-auto lg:max-h-screen"
        style={{
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* IE and Edge */,
          "&::WebkitScrollbar": {
            display: "none",
          } /* Chrome, Safari and Opera */,
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {displayedProperties.length > 0 ? (
            displayedProperties.map((property) => (
              <div
                className="flex flex-col"
                key={property.mlsNumber}
                onClick={(event) =>
                  handleClick(event, property.mlsNumber, property.boardId)
                }
              >
                <PropertyGridTile property={property} />
              </div>
            ))
          ) : (
            <p className="text-gray-600">
              No properties available. Please log out and try again.
            </p>
          )}
        </div>
      </div>

      {/* Removed pagination controls */}
    </motion.section>
  );
};

export default PropertiesListView;
