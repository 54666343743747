import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutThunk } from "../store/thunks/authThunk";
import { motion } from "framer-motion";
import { Disclosure, Menu } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  GiftIcon,
} from "@heroicons/react/24/outline";
import SearchBar from "./search/SearchBar";
import logo from "../assets/logo-small.png";

const navigation = [
  {
    name: "Discover",
    href: "/discover",
    current: window.location.pathname === "/discover",
  },
  {
    name: "Realtors",
    href: "/realtors",
    current: window.location.pathname === "/realtors",
  },
  {
    name: "Learn",
    href: "/learn",
    current: window.location.pathname === "/learn",
  },
  {
    name: "Favourites",
    href: "/favourites",
    current: window.location.pathname === "/favourites",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const handleLogout = async (e) => {
    e.preventDefault();
    navigate("/loading", {
      state: { message: "Logging out...", duration: 1500, redirectTo: "/" },
    });
    try {
      await dispatch(logoutThunk()).unwrap();
      navigate("/loading", {
        state: {
          message: "Logged out successfully",
          duration: 1000,
          redirectTo: "/",
        },
      });
    } catch (err) {
      console.error("Failed to logout: ", err);
    }
  };

  const userNavigation = [
    { name: "Settings", href: "/settings" },
    { name: "Sign out", href: "#", onClick: handleLogout },
  ];

  const handleNavigation = (e, item) => {
    e.preventDefault();
    navigate(item.href);
  };

  return (
    <div className="mb-16">
      <Disclosure
        as="nav"
        className="fixed z-50 bg-white border-b border-[#0A2342] shadow-lg w-full">
        {({ open }) => (
          <>
            <div className="mx-auto w-full lg:w-5/5 px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                {/* Left Section: Logo and Navigation */}
                <div className="flex items-center space-x-4 w-1/3">
                  <Link
                    to="/dashboard"
                    onClick={() => navigate("/dashboard")}
                    className="flex-shrink-0">
                    <img src={logo} className="h-12 w-auto" alt="Synergy AI" />
                  </Link>
                  <div className="hidden sm:flex sm:space-x-6">
                    {navigation.map((item) => (
                      <motion.a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => handleNavigation(e, item)}
                        className={classNames(
                          item.current
                            ? "text-[#0A2342]"
                            : "text-gray-500 hover:text-[#0A2342]",
                          "inline-flex items-center pt-1 text-sm transition-colors duration-200"
                        )}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}>
                        {item.name}
                      </motion.a>
                    ))}
                  </div>
                </div>

                {/* Center Section: SearchBar */}
                <div className="flex-grow mx-auto max-w-lg lg:max-w-2xl">
                  <SearchBar />
                </div>

                {/* Right Section: Gift, Notification, and User Menu */}
                <div className="flex items-center space-x-4 w-1/3 justify-end">
                  <motion.a
                    href="/refer-and-earn?utm_source=referral"
                    className="inline-flex items-center text-sm text-gray-500 hover:text-[#0A2342] transition-colors duration-200"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={(e) =>
                      handleNavigation(e, {
                        name: "Refer & Earn",
                        href: "/refer-and-earn?utm_source=referral",
                      })
                    }>
                    <GiftIcon className="h-6 w-6" aria-hidden="true" />
                  </motion.a>

                  <motion.button
                    type="button"
                    className="relative bg-white p-1 text-gray-400 hover:text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}>
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </motion.button>

                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <div className="h-8 w-8 rounded-full bg-white flex items-center justify-center text-[#0A2342] border border-[#0A2342]">
                          {user?.firstName?.charAt(0)}
                          {user?.lastName?.charAt(0)}
                        </div>
                      </Menu.Button>
                    </div>
                    <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-300 font-semibold text-[#0A2342] text-md"
                                  : "text-gray-500",
                                "block px-4 py-2 text-sm"
                              )}>
                              <a
                                href={item.href}
                                onClick={item.onClick}
                                className="block w-full h-full">
                                {item.name}
                              </a>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                </div>

                {/* Mobile Menu Button */}
                <div className="flex items-center sm:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-200 hover:text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#0A2342]">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            {/* Mobile menu panel */}
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pt-2 pb-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-50 text-[#0A2342] font-semibold text-lg"
                        : "text-gray-500 hover:bg-gray-200 hover:text-[#0A2342]",
                      "block px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}>
                    {item.name}
                  </Disclosure.Button>
                ))}

                {/* Gift and Notification icons in mobile menu */}
                <Disclosure.Button
                  as="a"
                  href="/refer-and-earn?utm_source=referral"
                  className="block px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-200 hover:text-[#0A2342]">
                  <GiftIcon
                    className="h-6 w-6 inline mr-2"
                    aria-hidden="true"
                  />
                  Refer & Earn
                </Disclosure.Button>
                <Disclosure.Button className="block px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-200 hover:text-[#0A2342]">
                  <BellIcon
                    className="h-6 w-6 inline mr-2"
                    aria-hidden="true"
                  />
                  Notifications
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Header;
