import React from "react";

// Import headshots from assets folder
import terrence from "../../assets/terrence.jpg";
import ibrahim from "../../assets/ibrahim.png";
import heaven from "../../assets/heaven.jpg";
import taki from "../../assets/taki.png";
import peter from "../../assets/peter.png";
import { motion } from "framer-motion";

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const Team = () => {
  return (
    <section id="team" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-[#0A2342]">
          Meet Our Team
        </h2>
        <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-8">
          {[
            {
              name: "Terrence Marshall",
              role: "Team Leader, Realtor",
              image: terrence,
            },
            {
              name: "Ibrahim Abowath",
              role: "Team Leader, Realtor",
              image: ibrahim,
            },
            {
              name: "Heaven Kaymen Joseph",
              role: "Partner, Realtor",
              image: heaven,
            },
            { name: "Taki Wong", role: "Chief Product Officer", image: taki },
            {
              name: "Peter Zhang",
              role: "Chief Technology Officer",
              image: peter,
            },
          ].map((member, index) => (
            <motion.div
              key={index}
              className="text-center"
              initial="initial"
              animate="animate"
              variants={fadeInUp}>
              <img
                src={member.image}
                alt={member.name}
                width={128}
                height={128}
                className="mx-auto mb-4 border-4 border-[#0A2342]"
              />
              <h3 className="font-semibold text-[#0A2342]">{member.name}</h3>
              <p className="text-gray-600">{member.role}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
