import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";

const LocalPlaces = ({ localData }) => {
  const [openLocalPlaces, setOpenLocalPlaces] = useState(true);
  const [activeSection, setActiveSection] = useState("schools");

  const sectionRefs = {
    local: useRef(null),
  };

  return (
    <motion.div
      ref={sectionRefs.local}
      className="bg-white p-4"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <h2
        className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
        onClick={() => setOpenLocalPlaces(!openLocalPlaces)}>
        Local Places
        <ChevronDown
          className={`ml-2 transform transition-transform ${
            openLocalPlaces ? "rotate-180" : "rotate-0"
          }`}></ChevronDown>
      </h2>
      {openLocalPlaces && (
        <>
          <div className="flex overflow-x-auto space-x-4 mb-4">
            <button
              onClick={() => setActiveSection("schools")}
              className={`px-4 py-2 whitespace-nowrap ${
                activeSection === "schools"
                  ? "bg-[#0A2342] text-white"
                  : "bg-white text-[#0A2342] border border-[#0A2342]"
              }`}>
              Schools
            </button>
            <button
              onClick={() => setActiveSection("parks")}
              className={`px-4 py-2 whitespace-nowrap ${
                activeSection === "parks"
                  ? "bg-[#0A2342] text-white"
                  : "bg-white text-[#0A2342] border border-[#0A2342]"
              }`}>
              Parks
            </button>
            <button
              onClick={() => setActiveSection("safety_places")}
              className={`px-4 py-2 whitespace-nowrap ${
                activeSection === "safety_places"
                  ? "bg-[#0A2342] text-white"
                  : "bg-white text-[#0A2342] border border-[#0A2342]"
              }`}>
              Safety Places
            </button>
            <button
              onClick={() => setActiveSection("transit_stops")}
              className={`px-4 py-2 whitespace-nowrap ${
                activeSection === "transit_stops"
                  ? "bg-[#0A2342] text-white"
                  : "bg-white text-[#0A2342] border border-[#0A2342]"
              }`}>
              Transit Stops
            </button>
          </div>

          {activeSection === "schools" && (
            <>
              <h3 className="text-lg font-semibold mb-2">Schools</h3>
              <div className="flex overflow-x-auto space-x-4">
                {localData.schools?.map((school, index) => (
                  <div key={index} className="min-w-[250px] mb-4 pb-4 border-b">
                    <h4 className="font-semibold text-[#0A2342]">
                      {school.name}
                    </h4>
                    <p className="text-sm text-gray-600">
                      {school.distance} km • {school.type} • Grades{" "}
                      {school.grades_offered[0]}-
                      {school.grades_offered[school.grades_offered.length - 1]}
                    </p>
                    {school.score !== null && (
                      <div className="flex items-center mt-2">
                        <div className="w-1/2 bg-gray-200 h-2.5 mr-2">
                          <div
                            className="bg-[#0A2342] h-2.5"
                            style={{
                              width: `${(school.score ?? 0) * 10}%`,
                            }}></div>
                        </div>
                        <span className="text-sm font-semibold">
                          {school.score ?? "N/A"}/10
                        </span>
                      </div>
                    )}
                  </div>
                )) ?? <p>No schools available.</p>}
              </div>
            </>
          )}

          {activeSection === "parks" && (
            <>
              <h3 className="text-lg font-semibold mb-2">Parks</h3>
              <div className="flex overflow-x-auto space-x-4">
                {localData.parks?.map((park, index) => (
                  <div key={index} className="min-w-[250px] mb-4 pb-4 border-b">
                    <h4 className="font-semibold text-[#0A2342]">
                      {park.name}
                    </h4>
                    {/* <p className="text-sm text-gray-600">{park.description}</p> */}
                    <p className="text-sm text-gray-600">
                      {park.distance} km • {park.facilities.join(", ")}
                    </p>
                  </div>
                )) ?? <p>No parks available.</p>}
              </div>
            </>
          )}

          {activeSection === "safety_places" && (
            <>
              <h3 className="text-lg font-semibold mb-2">Safety Places</h3>
              <div className="flex overflow-x-auto space-x-4">
                {localData.safety_places?.map((place, index) => (
                  <div key={index} className="min-w-[250px] mb-4 pb-4 border-b">
                    <h4 className="font-semibold text-[#0A2342]">
                      {place.name}
                    </h4>
                    {/* <p className="text-sm text-gray-600">{place.description}</p> */}
                    <p className="text-sm text-gray-600">{place.distance} km</p>
                  </div>
                )) ?? <p>No safety places available.</p>}
              </div>
            </>
          )}

          {activeSection === "transit_stops" && (
            <>
              <h3 className="text-lg font-semibold mb-2">Transit Stops</h3>
              <div className="flex overflow-x-auto space-x-4">
                {localData.transit_stops?.map((stop, index) => (
                  <div key={index} className="min-w-[250px] mb-4 pb-4 border-b">
                    <h4 className="font-semibold text-[#0A2342]">
                      {stop.name}
                    </h4>
                    <p className="text-sm text-gray-600">
                      {stop.distance} km
                      {stop.walking_time && (
                        <> • {Math.round(stop.walking_time * 10) / 10} mins</>
                      )}
                    </p>
                  </div>
                )) ?? <p>No transit stops available.</p>}
              </div>
            </>
          )}
        </>
      )}
    </motion.div>
  );
};

export default LocalPlaces;
