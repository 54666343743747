import React from "react";
import { motion } from "framer-motion";
import WaitlistEmail from "./WaitlistEmail";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const CTA = () => {
  return (
    <section id="contact" className="py-20 bg-[#0A2342] text-white">
      <div className="container mx-auto px-4 text-center">
        <motion.div initial="initial" animate="animate" variants={fadeInUp}>
          <h2 className="text-3xl font-bold mb-6">
            Ready to Transform Your Home Buying Experience?
          </h2>
          <p className="text-xl mb-8">
            Join our waitlist and be the first to experience the future of real
            estate.
          </p>
          <div className="max-w-md mx-auto">
            <WaitlistEmail />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default CTA;
