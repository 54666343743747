import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPropertiesThunk,
  fetchPersonalizedPropertiesThunk,
} from "../store/thunks/repliersThunk";
import PropertyCarousel from "../components/properties/PropertyCarousel";
import FindYourDream from "../components/FindYourDream";
import SearchBar from "../components/search/SearchBar";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  // Access the NLP and search results from Redux state
  const { nlpSearchResults, nlpStatus, nlpSearchSummary } = useSelector(
    (state) => state.repliers
  );
  const searchPropertyResults = useSelector(
    (state) => state.repliers.searchPropertyResults
  );
  const searchPropertyStatus = useSelector(
    (state) => state.repliers.searchPropertyStatus
  );

  const [generalListings, setGeneralListings] = useState([]);
  const [personalizedListings, setPersonalizedListings] = useState([]);
  const [loading, setLoading] = useState(true);

  // Add logging to see if nlpSearchResults and nlpStatus are updating
  useEffect(() => {
    console.log("DashboardPage - nlpStatus:", nlpStatus);
    console.log("DashboardPage - nlpSearchResults:", nlpSearchResults);
    console.log("DashboardPage - nlpSearchSummary:", nlpSearchSummary);
  }, [nlpStatus, nlpSearchResults, nlpSearchSummary]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Check if tokens array exists and has tokens
      const currentToken = user?.tokens?.[user.tokens.length - 1]?.token;

      if (!currentToken) {
        console.error("Token is undefined");
        setLoading(false);
        return;
      }

      try {
        const personalizedResult = await dispatch(
          fetchPersonalizedPropertiesThunk()
        ).unwrap();
        setPersonalizedListings(personalizedResult.listings || []);
      } catch (error) {
        console.error("Error fetching personalized properties:", error);
      }

      try {
        const generalResult = await dispatch(fetchPropertiesThunk()).unwrap();
        setGeneralListings(generalResult.listings || []);
      } catch (error) {
        console.error("Error fetching general properties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, user]);

  return (
    <div className="bg-gray-100 min-h-screen">
      <main className="container mx-auto py-8">
        <FindYourDream />

        {loading ? (
          <div className="text-center">
            <div className="loader"></div>
            <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
              Gathering property info...
            </h2>
          </div>
        ) : (
          <>
            {/* NLP Listings */}
            {nlpStatus === "succeeded" &&
              nlpSearchResults?.listings.length > 0 && (
                <PropertyCarousel
                  title={`NLP Listings (${nlpSearchResults.count})`}
                  properties={nlpSearchResults.listings}
                  description={nlpSearchSummary}
                  pageSize={10}
                />
              )}

            {/* Searched Properties */}
            {searchPropertyStatus === "succeeded" &&
              searchPropertyResults?.listings?.length > 0 && (
                <PropertyCarousel
                  title="Searched Properties"
                  properties={searchPropertyResults.listings}
                  description="Properties based on your search."
                  pageSize={10}
                />
              )}

            {/* Personalized Recommendations */}
            {personalizedListings.length > 0 && (
              <PropertyCarousel
                title="Personalized Recommendations"
                properties={personalizedListings}
                description="These are personalized recommendations based on your onboarding preferences."
                pageSize={10}
              />
            )}

            {/* General Recommendations */}
            {generalListings.length > 0 && (
              <PropertyCarousel
                title="General Recommendations"
                properties={generalListings}
                description="These are general recommendations."
                pageSize={10}
              />
            )}

            {/* No Listings Available */}
            {!personalizedListings.length &&
              !generalListings.length &&
              !nlpSearchResults?.length &&
              !searchPropertyResults?.listings?.length && (
                <div className="text-center">
                  <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
                    No properties available at the moment. Try logging out and
                    in again.
                  </h2>
                </div>
              )}
          </>
        )}
      </main>
    </div>
  );
};

export default DashboardPage;
