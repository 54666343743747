import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { message, message2, duration, redirectTo } = location.state || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(redirectTo);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, navigate, redirectTo]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#f0f4f8]">
      <motion.div
        className="bg-white p-10 shadow-xl text-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}>
        <motion.div
          className="inline-block w-4 h-4 bg-[#0A2342] mb-6"
          animate={{ x: ["100%", "-100%"] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}></motion.div>
        <h2 className="text-2xl font-bold text-[#0A2342]">{message}</h2>
        <h2 className="text-2xl font-bold text-[#0A2342]">{message2}</h2>
      </motion.div>
    </div>
  );
};

export default LoadingScreen;
