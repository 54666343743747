import React from "react";
import { motion } from "framer-motion";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import FloatingImage from "./FloatingImage";
import WaitlistEmail from "./WaitlistEmail";

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

export default function Hero() {
  return (
    <section
      id="hero"
      className="relative pt-32 pb-20 bg-gradient-to-br from-white to-gray-200">
      <div className="container mx-auto px-4">
        <motion.div
          className="max-w-3xl mx-auto text-center pb-20"
          initial="initial"
          animate="animate"
          variants={fadeInUp}>
          <h1 className="text-5xl font-bold mb-6 text-[#0A2342]">
            Revolutionize Your Home Buying Experience
          </h1>
          <p className="text-xl mb-8 text-gray-700">
            Synergy AI empowers homebuyers with cutting-edge AI technology for a
            seamless and personalized real estate journey.
          </p>

          <div className="max-w-md mx-auto">
            <WaitlistEmail />
          </div>
        </motion.div>
        <FloatingImage />
      </div>
    </section>
  );
}
