import React from "react";
import {
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  UsersIcon,
  HomeModernIcon,
} from "@heroicons/react/24/outline";
import logo from "../../assets/Logo Text.png"; // Ensure the path is correct
import { motion } from "framer-motion";

// Import headshots from assets folder
import terrence from "../../assets/terrence.jpg";
import ibrahim from "../../assets/ibrahim.png";
import heaven from "../../assets/heaven.jpg";
import taki from "../../assets/taki.png";
import peter from "../../assets/peter.png";

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const awards = [
  {
    title: "Ranked #1 Group in Keller Williams Legacies",
    icon: CheckBadgeIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "Ranked #6 Group in Canada, Q1 2024",
    icon: CheckBadgeIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "$175M sales volume",
    icon: BanknotesIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "200+ properties sold",
    icon: HomeModernIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "10+ years of experience",
    icon: ClockIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "150+ happy clients",
    icon: UsersIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AboutUs = () => {
  return (
    <section id="about" className="py-20 bg-gray-200">
      <div className="container mx-auto px-4">
        <motion.div
          className="max-w-3xl mx-auto text-center"
          initial="initial"
          animate="animate"
          variants={fadeInUp}>
          <h2 className="text-3xl font-bold mb-6 text-[#0A2342]">
            About Synergy AI
          </h2>
          <p className="text-xl mb-8 text-gray-700">
            We're a cutting-edge brokerage platform combining AI technology with
            human expertise to provide an unparalleled home buying experience.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {awards.map((award, index) => (
              <motion.div
                key={index}
                className="bg-white p-4 shadow-md hover:shadow-lg transition-shadow transform hover:scale-105"
                whileHover={{ scale: 1.05 }}>
                <award.icon className="h-8 w-8 text-[#0A2342] mx-auto mb-2" />
                <p className="text-sm text-gray-700">{award.title}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;
