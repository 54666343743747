import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/apiConfig";

export const fetchLandingPage = createAsyncThunk(
  "data/fetchLandingPage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post("/api/landingpage");
      return response.data;
    } catch (error) {
      console.error("Error fetching landing page data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch landing page data"
      );
    }
  }
);

export const joinWaitlistThunk = createAsyncThunk(
  "data/joinWaitlist",
  async (email, { getState, rejectWithValue }) => {
    try {
      const response = await api.post("/api/waitlist", { email });
      return { email, message: response.data.message }; // Return the email and message as the payload
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Failed to join waitlist" }
      );
    }
  }
);
