import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPropertiesThunk } from "../store/thunks/repliersThunk";
import DiscoverMap from "../components/discoverPage/DiscoverMap";

import { Switch } from "@headlessui/react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import * as Slider from "@radix-ui/react-slider";
import FilterBar from "../components/discoverPage/FilterBar";
import PropertiesListView from "../components/discoverPage/PropertiesListView";

export default function DiscoverPage() {
  const dispatch = useDispatch();
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);

  const [priceRange, setPriceRange] = useState([0, 3000000]);
  const [bedroomRange, setBedroomRange] = useState([0, Infinity]);
  const [bathroomRange, setBathroomRange] = useState([0, Infinity]);
  const [propertyType, setPropertyType] = useState("");
  const [listingType, setListingType] = useState("Sale");
  const [squareFootageRange, setSquareFootageRange] = useState([0, 5000]); // Default range
  const [showSold, setShowSold] = useState(false);

  const mapRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);
  const [areFiltersVisible, setAreFiltersVisible] = useState(true);
  const [filtersReset, setFiltersReset] = useState(false); // tracks if filters have been reset

  // Default location for the map
  const [propertyCoordinates, setPropertyCoordinates] = useState([]);

  const [currentLocation, setCurrentLocation] = useState(null);
  // APPLYING FILTERS
  const applyFilters = () => {
    const filtered = listings.filter(property => {
      const price = property.listPrice;
      const bedrooms = property.details.numBedrooms;
      const bathrooms = property.details.numBathrooms;
      const classType = property.class;

      const isPriceValid = price >= priceRange[0] && price <= priceRange[1];
      const isBedroomValid = bedrooms >= bedroomRange[0] && bedrooms <= bedroomRange[1];
      const isBathroomValid = bathrooms >= bathroomRange[0] && bathrooms <= bathroomRange[1];
      const isTypeValid = propertyType === "" || propertyType === classType;

      if (!property.details.sqft) {
        return isPriceValid && isBedroomValid && isBathroomValid && isTypeValid;
      }

      const [propertyMinSqft, propertyMaxSqft] = parseSquareFootage(property.details.sqft);
      const isSquareFootageValid =
        (propertyMinSqft >= squareFootageRange[0] && propertyMinSqft <= squareFootageRange[1]) ||
        (propertyMaxSqft >= squareFootageRange[0] && propertyMaxSqft <= squareFootageRange[1]) ||
        (squareFootageRange[0] >= propertyMinSqft && squareFootageRange[0] <= propertyMaxSqft) ||
        (squareFootageRange[1] >= propertyMinSqft && squareFootageRange[1] <= propertyMaxSqft);

      return isPriceValid && isBedroomValid && isBathroomValid && isSquareFootageValid && isTypeValid;
    });

    setFilteredListings(filtered);

    // Update map coordinates based on filtered properties
    const filteredCoordinates = filtered.map(property => ({
      address: property.address,
      longitude: property.map?.longitude,
      latitude: property.map?.latitude,
    }));

    setPropertyCoordinates(filteredCoordinates);
  };
  
  // useEffect(() => {
  //   applyFilters();

  //   const timeoutId = setTimeout(() => {
  //     console.log("settimeout");
  //     applyFilters();
  //   }, 3000);

  //   return () => clearTimeout(timeoutId);
  // }, []);

  // RESETTING FILTERS
  const resetFilters = () => {
    setPriceRange([0, 3000000]); 
    setBedroomRange([0, Infinity]); 
    setBathroomRange([0, Infinity]); 
    setPropertyType("");
    setListingType("Sale"); 
    setSquareFootageRange([0, 5000]); 
    setShowSold(false); 

    // Reset filtered listings and map coordinates to all listings
    setFilteredListings(listings);
    setPropertyCoordinates(listings.map(property => ({
      address: property.address,
      longitude: property.map?.longitude,
      latitude: property.map?.latitude,
    })));
  };

  // Call applyFilters after setting initial listings
  useEffect(() => {
    if (listings.length > 0) {
      applyFilters();
    }
  }, [listings]);

  const parseSquareFootage = (sqftString) => {
    if (typeof sqftString !== 'string') {
      console.warn("Invalid square footage format:", sqftString); // Log a warning for debugging
      return [0, Infinity]; // Return a default value if the format is invalid
    }
    
    const [min, max] = sqftString.split('-').map(Number); // Split the string and convert to numbers
    return [min, max]; // Return as an array
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(fetchPropertiesThunk()).unwrap();
      const initialListings = result.listings || [];
      setListings(initialListings);
      setFilteredListings(initialListings); // Set filtered listings initially
      setPropertyCoordinates(initialListings.map(property => ({
        address: property.address,
        longitude: property.map?.longitude,
        latitude: property.map?.latitude,
      }))); // Set initial map coordinates
    };
    fetchData();
  }, [dispatch]);

  // running the getCurrentLocation function
  useEffect(() => {
    console.log("getCurrentLocation ran")
    getCurrentLocation();
  }, []); 
  console.log("user location disc page", userLocation)
// gets the users location
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  // Extract coordinates from propertyData
  useEffect(() => {
    if (listings && Array.isArray(listings)) {
      const coordinates = listings
        .filter(property => {
          const price = property.listPrice;
          return price >= priceRange[0] && price <= priceRange[1];
        })
        .map((property) => ({
          address: property.address,
          longitude: property.map?.longitude,
          latitude: property.map?.latitude,
        }));
      // console.log("Extracted coordinates:", coordinates);
      setPropertyCoordinates(coordinates);
    }
  }, [listings, priceRange]);

  // // Function to handle changes in the bedroom range inputs
  // const handleBedroomRangeChange = (min, max) => {
  //   const parsedMin = parseInt(min) || 0; // Default to 0 if NaN
  //   const parsedMax = max === "" ? Infinity : parseInt(max); // Default to Infinity if empty
  //   setBedroomRange([parsedMin, parsedMax]);
  // };




  return (
    <div className="flex flex-col md:flex-row bg-gray-50">
      {/* Sidebar (Filters) */}
      <FilterBar
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        // applyPriceFilter={applyFilters}

        bedroomRange={bedroomRange}
        setBedroomRange={setBedroomRange}
        // applyBedroomFilter={applyFilters}
        bathroomRange={bathroomRange}
        setBathroomRange={setBathroomRange}

        propertyType={propertyType}
        setPropertyType={setPropertyType}
        listingType={listingType}
        setListingType={setListingType}
        squareFootageRange={squareFootageRange}
        setSquareFootageRange={setSquareFootageRange}
        showSold={showSold}
        setShowSold={setShowSold}
        
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        properties={listings}
        setFilteredProperties={setFilteredListings}

        getCurrentLocation={getCurrentLocation}
      />

      <div className="flex flex-col md:flex-row bg-gray-50 md:mt-16 mt-6 w-full">
        {/* Map Section (First on Mobile, Second on Tablet and Larger) */}
        <div className="flex-grow w-full md:w-2/5 h-full transition-all order-1 md:order-2">
          <DiscoverMap
            properties={listings}
            locations={propertyCoordinates.map(
              ({ address, longitude, latitude }) => ({
                address,
                longitude,
                latitude,
              })
            )}
            userLocation={userLocation}
            ref={mapRef}
            applyFilters={applyFilters}
          />
        </div>

        {/* Property Carousel Section (Second on Mobile, First on Tablet and Larger) */}
        <div className="flex-grow w-full md:w-3/5 h-full order-2 md:order-1">
          <PropertiesListView
            properties={filteredListings}
          />
        </div>
      </div>
    </div>
  );
}
