import React, { useState } from "react";
import HistoryTable from "./HistoryTable";
import HistoryChart from "./HistoryChart";

const PropertyHistory = ({ history }) => {
  const [viewMode, setViewMode] = useState("table");

  return (
    <div className="">
      <div className="flex gap-4 pb-4">
        <button
          className={`px-4 py-2 ${
            viewMode === "table" ? "bg-[#0A2342] text-white" : "bg-gray-300"
          }`}
          onClick={() => setViewMode("table")}
          style={{ transition: "transform 0.2s" }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}>
          Table View
        </button>
        <button
          className={`px-4 py-2 ${
            viewMode === "chart" ? "bg-[#0A2342] text-white" : "bg-gray-300"
          }`}
          onClick={() => setViewMode("chart")}
          style={{ transition: "transform 0.2s" }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}>
          Chart View
        </button>
      </div>

      <div className="overflow-auto max-h-[400px]">
        {viewMode === "table" ? (
          <HistoryTable history={history} />
        ) : (
          <HistoryChart history={history} />
        )}
      </div>
    </div>
  );
};

export default PropertyHistory;
