import React, { useState } from "react";
import { useSelector } from "react-redux";

const RealtorPage = () => {
  const [activeRealtor, setActiveRealtor] = useState(null);

  const user = useSelector((state) => state.auth.user); // Get the user from the store

  const realtors = [
    {
      name: "Terrence",
      image: require("../assets/terrence.jpg"),
      blurb:
        "Terrence is an experienced realtor with a passion for helping clients find their dream homes.",
    },
    {
      name: "Ibrahim",
      image: require("../assets/ibrahim.png"),
      blurb:
        "Ibrahim specializes in luxury properties and has a keen eye for detail.",
    },
    {
      name: "Heaven",
      image: require("../assets/heaven.jpg"),
      blurb:
        "Heaven is known for his exceptional customer service and dedication to his clients.",
    },
  ];

  const handleContactClick = (index) => {
    setActiveRealtor(activeRealtor === index ? null : index);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-3xl font-bold text-[#0A2342] my-6 text-center">
        Meet Our Realtors
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full max-w-4xl">
        {realtors.map((realtor, index) => (
          <div key={index} className="bg-white p-4 shadow-md text-center">
            <img
              src={realtor.image}
              alt={realtor.name}
              width={128}
              height={128}
              className="mx-auto mb-4 border-2 border-[#0A2342]"
            />
            <h2 className="text-xl font-semibold text-[#0A2342]">
              {realtor.name}
            </h2>
            <p className="text-gray-600 mb-4">{realtor.blurb}</p>
            <button
              onClick={() => handleContactClick(index)}
              className="bg-[#0A2342] text-white py-2 px-4">
              Get in Touch
            </button>
            {activeRealtor === index && (
              <form className="mt-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="border border-gray-300 p-2 w-full mb-2"
                  required
                  defaultValue={`${user.firstName} ${user.lastName}`} // Prepopulate with user's first and last name
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  className="border border-gray-300 p-2 w-full mb-2"
                  required
                  defaultValue={user.username} // Prepopulate with user's email
                />
                <textarea
                  placeholder="Your Message"
                  className="border border-gray-300 p-2 w-full mb-2"
                  rows="3"
                  required
                />
                <button
                  type="submit"
                  className="bg-[#E5B13A] text-[#0A2342] py-2 px-4">
                  Send Message
                </button>
              </form>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RealtorPage;
