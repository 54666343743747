import React from "react";
import { Link } from "react-router-dom";

const CollectionCard = ({ collection, onEdit, onDelete }) => {
  return (
    <div className="bg-white p-4 rounded shadow">
      <h2 className="text-xl font-semibold">{collection.name}</h2>
      <button onClick={() => onEdit(collection)} className="text-blue-500">Edit</button>
      <button onClick={() => onDelete(collection)} className="text-red-500 ml-2">Delete</button>
      <Link to="/discover" className="text-green-500 ml-2">+</Link>
      {/* Display properties in the collection */}
      <div>
        {collection.properties.length > 0 ? (
          collection.properties.map((property) => (
            <div key={property.id} className="flex justify-between">
              <span>{property.name}</span>
              <button onClick={() => {/* Remove property logic */}} className="text-red-500">Remove</button>
            </div>
          ))
        ) : (
          <p>No properties in this collection.</p>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
