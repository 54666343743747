import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 h-screen flex items-center justify-center p-4">
      <div className="text-center">
        <h1 className="text-6xl md:text-9xl font-extrabold text-gray-800">404</h1>
        <p className="text-xl md:text-2xl font-semibold mt-4 text-gray-600">
          Oops! Page not found.
        </p>
        <p className="text-base md:text-lg text-gray-500 mt-2">
          It seems like the page you're looking for doesn't exist.
        </p>
        <p className="text-base md:text-lg text-gray-500 mt-2">
          We've temporarily taken this page offline. Please check back later.
        </p>
        <div className="mt-6">
          <button
            onClick={() => navigate("/")}
            className="inline-block px-4 py-2 md:px-6 md:py-3 text-white bg-[#0A2342] hover:bg-gray-600 font-medium">
            Back to Home
          </button>
        </div>
        {/* <div className="mt-6">
          <p className="text-sm text-gray-500">
            If you believe this is an error, please contact support.
          </p>
        </div> */}
      </div>
    </div>
  );
}
