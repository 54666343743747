import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchPdfs = createAsyncThunk(
  "pdfs/fetchPdfs",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get("/api/pdfs", config); // No need for blob response type here
      return response.data;
    } catch (error) {
      console.error("Error fetching PDFs:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch PDFs"
      );
    }
  }
);

export const fetchPdfBlob = createAsyncThunk(
  "pdfs/fetchPdfBlob",
  async (fileName, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get(`/api/pdfs/${fileName}`, {
        ...config,
        responseType: "blob", // Important: Set responseType to 'blob'
      });

      return {
        fileName,
        fileBlob: response.data,
        fileType: response.headers["content-type"], // Get content type for correct download
      };
    } catch (error) {
      console.error("Error fetching PDF:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch PDF"
      );
    }
  }
);
