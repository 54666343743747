import { createSlice } from "@reduxjs/toolkit";
import { fetchPdfs, fetchPdfBlob } from "../thunks/pdfsThunk";

const pdfsSlice = createSlice({
  name: "pdfs",
  initialState: {
    pdfs: [],
    fileUrl: null, // Store URL instead of Blob
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPdfs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPdfs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pdfs = action.payload;
      })
      .addCase(fetchPdfs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPdfBlob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPdfBlob.fulfilled, (state, action) => {
        state.status = "succeeded";
        const fileUrl = action.payload.fileUrl;

        // Store the file URL directly
        state.fileUrl = fileUrl;
      })
      .addCase(fetchPdfBlob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default pdfsSlice.reducer;
