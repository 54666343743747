// PropertyCarousel.js

import React, { useState } from "react";
import { motion } from "framer-motion";
import PropertyGridTile from "./PropertyGridTile";
import {
  InformationCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

const PropertyCarousel = ({ title, properties, description, pageSize = 8 }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const propertiesArray = Array.isArray(properties) ? properties : [];
  const validPageSize = pageSize > 0 ? pageSize : 8;
  const totalPages = Math.ceil(propertiesArray.length / validPageSize);

  const paginatedProperties = propertiesArray.slice(
    (currentPage - 1) * validPageSize,
    currentPage * validPageSize
  );

  const handleClick = (event, mlsNumber, boardId) => {
    const url = `/propertydetails/${mlsNumber}/${boardId}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <motion.section
      className="my-6"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      {title && (
        <div className="flex items-center mb-4 pl-4 relative">
          <h2 className="text-xl sm:text-2xl font-bold text-[#0A2342] flex items-center">
            {title}
            {description && (
              <button
                className="text-blue-500 ml-2"
                onClick={() => setShowTooltip(!showTooltip)}>
                <InformationCircleIcon className="w-5 h-5 text-[#0A2342]" />
              </button>
            )}
          </h2>
          {showTooltip && (
            <div className="text-sm absolute bottom-full mb-1 w-80 bg-white border border-gray-300 p-2 text-[#0A2342] shadow-lg z-10">
              {description}
            </div>
          )}
        </div>
      )}

      <div className="container mx-auto">
        {/* Vertical scroll on small screens, horizontal scroll with limited items on large screens */}
        <div
          className={`flex ${
            window.innerWidth >= 1024
              ? "flex-nowrap overflow-x-auto space-x-4"
              : "flex-wrap overflow-y-auto"
          } space-y-4 sm:space-y-0 sm:space-x-4 md:px-1.5`}>
          {paginatedProperties.length > 0 ? (
            paginatedProperties.map((property, index) => (
              <div
                className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 ${
                  window.innerWidth >= 1024 ? "flex-shrink-0" : ""
                } py-2 ${index === 0 ? "pl-4" : ""} ${
                  index === paginatedProperties.length - 1 ? "pr-4" : ""
                } ${window.innerWidth < 1024 ? "px-4" : "px-0"}`}
                key={property.mlsNumber}
                onClick={(event) =>
                  handleClick(event, property.mlsNumber, property.boardId)
                }>
                <PropertyGridTile property={property} />
              </div>
            ))
          ) : (
            <p className="text-gray-600">
              No properties available. Please log out and try again.
            </p>
          )}
        </div>

        <div className="flex justify-between items-center mt-4 px-4">
          <button
            className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}>
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="text-sm">Previous</span>
          </button>

          <span className="text-sm text-[#0A2342]">
            Page {currentPage} of {totalPages}
          </span>

          <button
            className="flex items-center space-x-2 text-[#0A2342] disabled:opacity-50"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}>
            <span className="text-sm">Next</span>
            <ArrowRightIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </motion.section>
  );
};

export default PropertyCarousel;
