import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BellIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import {
  fetchOnboarding,
  updateOnboarding,
} from "../store/thunks/onboardingThunk";
import OnboardingQuestions from "../components/onboardingQuestions";
import { motion } from "framer-motion";

const secondaryNavigation = [
  { name: "General", href: "#", icon: UserCircleIcon, current: true },
  { name: "Preferences", href: "#", icon: BellIcon, current: false },
  { name: "Security", href: "#", icon: FingerPrintIcon, current: false },
  { name: "Notifications", href: "#", icon: BellIcon, current: false },
  { name: "Plan", href: "#", icon: CubeIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingsPage() {
  const entireState = useSelector((state) => state);
  // const onboardingData = useSelector(state => state.onboarding?.answers);

  const onboardingData = useSelector(
    (state) =>
      state.onboarding?.error?.existingOnboarding ||
      state.onboarding?.answers ||
      {}
  );

  const user = useSelector((state) => state.auth.user);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState("General");
  const [editedData, setEditedData] = useState(onboardingData);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOnboarding());

    console.log("Entire Redux State:", entireState);
    console.log("Onboarding Data:", onboardingData);
  }, [entireState, onboardingData]);

  const handleEdit = (key, value) => {
    setEditedData((prev) => ({ ...prev, [key]: value }));
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await dispatch(updateOnboarding(editedData)).unwrap();
      setIsEditing(false);
      // Update the local state with the new data
      dispatch({ type: "onboarding/updateAnswers", payload: editedData });
    } catch (error) {
      console.error("Failed to update preferences:", error);
    }
  };

  const renderPreferences = () => {
    if (!onboardingData) {
      return <p>Loading your onboarding information...</p>;
    }

    return (
      <motion.div
        className="bg-white p-4 rounded-lg shadow-md" // Added shadow and rounded edges
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <h2 className="text-xl font-semibold mb-4 text-[#0A2342]">
          Preferences
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Your onboarding preferences are displayed below.
        </p>
        <dl className="mt-6 space-y-6 divide-y divide-gray-100">
          {OnboardingQuestions.map((question) => (
            <div key={question.key} className="pt-6">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {question.name}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700">
                {isEditing ? (
                  question.type === "ranking" ? (
                    <div>Ranking edit not implemented</div>
                  ) : question.options ? (
                    <select
                      value={
                        editedData[question.key] || onboardingData[question.key]
                      }
                      onChange={(e) => handleEdit(question.key, e.target.value)}
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[#0A2342] focus:ring focus:ring-[#0A2342] focus:ring-opacity-50">
                      {question.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      value={
                        editedData[question.key] || onboardingData[question.key]
                      }
                      onChange={(e) => handleEdit(question.key, e.target.value)}
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[#0A2342] focus:ring focus:ring-[#0A2342] focus:ring-opacity-50"
                    />
                  )
                ) : Array.isArray(onboardingData[question.key]) ? (
                  onboardingData[question.key].join(", ")
                ) : (
                  onboardingData[question.key]
                )}
              </dd>
            </div>
          ))}
        </dl>
        <div className="mt-6">
          {isEditing ? (
            <button
              onClick={handleSave}
              className="w-full bg-[#0A2342] text-white py-2 mt-4">
              Save Changes
            </button>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 mt-4">
              Edit Preferences
            </button>
          )}
        </div>
      </motion.div>
    );
  };

  const renderSection = () => {
    switch (currentSection) {
      case "General":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Profile
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              This information was automatically gathered from your
              registration.
            </p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  First Name
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{user.firstName}</div>
                  <button
                    type="button"
                    className="font-semibold text-[#0A2342] hover:text-gold-500">
                    Update
                  </button>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Last Name
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{user.lastName}</div>
                  <button
                    type="button"
                    className="font-semibold text-[#0A2342] hover:text-gold-500">
                    Update
                  </button>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Username
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{user.username}</div>
                  <button
                    type="button"
                    className="font-semibold text-[#0A2342] hover:text-gold-500">
                    Update
                  </button>
                </dd>
              </div>
            </dl>
          </div>
        );
      case "Preferences":
        return renderPreferences();
      case "Security":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Security Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Manage your security settings here.
            </p>
            {/* Add more security settings here */}
          </div>
        );
      case "Notifications":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Notification Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Manage your notification preferences here.
            </p>
            {/* Add more notification settings here */}
          </div>
        );
      case "Plan":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Plan Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              View and manage your plan details here.
            </p>
            {/* Add more plan details here */}
          </div>
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (currentSection) {
      case "General":
        return renderSection();
      case "Security":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Security Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Manage your security settings here.
            </p>
            {/* Add more security settings here */}
          </div>
        );
      case "Preferences":
        return renderPreferences();
      case "Notifications":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Notification Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Manage your notification preferences here.
            </p>
            {/* Add more notification settings here */}
          </div>
        );
      case "Plan":
        return (
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Plan Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              View and manage your plan details here.
            </p>
            {/* Add more plan details here */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="mx-auto max-w-7xl pt-8 lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-10">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    onClick={() => setCurrentSection(item.name)}
                    className={classNames(
                      currentSection === item.name
                        ? "border border-[#0A2342] text-gold-600"
                        : "text-gray-700 hover:border hover:border-[#0A2342] hover:text-gold-600",
                      "group flex gap-x-3 py-2 pl-2 pr-3 text-sm font-semibold leading-6"
                    )}>
                    <item.icon
                      aria-hidden="true"
                      className={classNames(
                        currentSection === item.name
                          ? "text-gold-600"
                          : "text-gray-400 group-hover:text-gold-600",
                        "h-6 w-6 shrink-0"
                      )}
                    />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-8 sm:px-6 lg:flex-auto lg:px-0 lg:py-10">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            {renderContent()}
          </div>
        </main>
      </div>
    </>
  );
}
