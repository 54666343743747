import React from "react";
import {
  ChartBarIcon,
  SparklesIcon,
  LinkIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const Feature = () => {
  const primaryFeatures = [
    {
      name: "Personalized Property Recommendations",
      description:
        "Our AI-driven system learns from your preferences and behavior to provide property suggestions that match your exact criteria, saving you time and effort in your home search.",
      icon: SparklesIcon,
    },
    {
      name: "Quality Realtor Connections",
      description:
        "Connect with award-winning, highly rated realtors who are matched to your specific needs and preferences, ensuring a trustworthy and personalized homebuying experience.",
      icon: LinkIcon,
    },
    {
      name: "Improved Communications",
      description:
        "Enjoy seamless communication with your realtor through integrated tools that allow for easy messaging, document sharing, and scheduling, ensuring you stay informed and on track.",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: "Real-Time Market Data",
      description:
        "Stay ahead with up-to-date market insights and neighborhood information, helping you make informed decisions about where and when to buy.",
      icon: ChartBarIcon,
    },
  ];

  return (
    <section id="features" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-[#0A2342]">
          Intelligent Features
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {primaryFeatures.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-gray-100 p-6 shadow-lg hover:shadow-xl transition-shadow transform hover:scale-105"
              initial="initial"
              animate="animate"
              variants={fadeInUp}>
              <feature.icon className="h-12 w-12 text-[#0A2342] mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-[#0A2342]">
                {feature.name}
              </h3>
              <p className="text-gray-700">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Feature;
