import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from "react";
import * as maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
const DEFAULT_LOCATION = { longitude: -79.3871, latitude: 43.6426 }; // CN Tower, Toronto

const Map = forwardRef(({ locations, userLocation, applyFilters }, ref) => {
    const mapContainer = useRef(null);
    const mapInstance = useRef(null);

    const [mapStyle, setMapStyle] = useState("topo");

    useImperativeHandle(ref, () => ({
        flyTo: (options) => {
            if (mapInstance.current) {
                mapInstance.current.flyTo(options);
            }
        },
    }));

    useEffect(() => {
        if (!mapInstance.current) {
            const map = new maplibregl.Map({
                container: mapContainer.current,
                style: `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`,
                center: [DEFAULT_LOCATION.longitude, DEFAULT_LOCATION.latitude],
                minZoom: 3,
                maxZoom: 18,
                pitch: 50,
                zoom: 8,
            });

            mapInstance.current = map;

            map.addControl(new maplibregl.NavigationControl(), "top-right");

            map.on("load", () => {
                // Add user location point if available
                if (userLocation) {
                    map.addSource("userLocation", {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: [
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: [userLocation.longitude, userLocation.latitude],
                                    },
                                    properties: {},
                                },
                            ],
                        },
                    });

                    map.addLayer({
                        id: "user-location",
                        type: "circle",
                        source: "userLocation",
                        paint: {
                            "circle-color": "#FF0000", // Red color for user location
                            "circle-radius": 10,
                        },
                    });
                }

                // Call updateSourceData only after the map is fully loaded
                updateSourceData(map, locations);

                map.addSource("properties", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                });

                map.addLayer({
                    id: "clusters",
                    type: "circle",
                    source: "properties",
                    filter: ["has", "point_count"],
                    paint: {
                        // synergy gold
                        "circle-color": ["step", ["get", "point_count"], "#B79C70", 100, "#B79C70", 750, "#B79C70"],
                        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
                        "circle-stroke-width": 2,
                        "circle-stroke-color": "#fff",
                    },
                });

                map.addLayer({
                    id: "cluster-count",
                    type: "symbol",
                    source: "properties",
                    filter: ["has", "point_count"],
                    layout: {
                        "text-field": "{point_count_abbreviated}",
                        "text-size": 12,
                    },
                });

                // map.addLayer({
                //     id: "user-point",
                //     type: "circle",
                //     source: "userLocation",
                //     filter: ["!", ["has", "point_count"]],
                //     paint: {
                //         // synergy blue
                //         "circle-color": "#fff",
                //         "circle-radius": 15,
                //         "circle-stroke-width": 2,
                //         "circle-stroke-color": "#fff",
                //     },
                // });

                map.addLayer({
                    id: "unclustered-point",
                    type: "circle",
                    source: "properties",
                    filter: ["!", ["has", "point_count"]],
                    paint: {
                        // synergy blue
                        "circle-color": "#1E2C53",
                        "circle-radius": 10,
                        "circle-stroke-width": 2,
                        "circle-stroke-color": "#fff",
                    },
                });

                map.on("mouseenter", "unclustered-point", () => {
                    map.getCanvas().style.cursor = "pointer";
                });
                map.on("mouseleave", "unclustered-point", () => {
                    map.getCanvas().style.cursor = "";
                });

                map.on("click", "clusters", async (e) => {
                    const features = map.queryRenderedFeatures(e.point, {
                        layers: ["clusters"],
                    });
                    const clusterId = features[0].properties.cluster_id;
                    const expansionZoom = await map.getSource("properties").getClusterExpansionZoom(clusterId);

                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: expansionZoom,
                    });
                });

                map.on("click", "unclustered-point", (e) => {
                    const { properties } = e.features[0];
                    const coordinates = e.features[0].geometry.coordinates.slice();

                    const address = properties.address ? JSON.parse(properties.address) : {};

                    const getAddressDetail = (detail) => (address[detail] ? address[detail] : "N/A");

                    new maplibregl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(`
                            <div class="text-sm text-gray-600">
                                <p><strong>Address:</strong> ${getAddressDetail("streetNumber")} ${getAddressDetail("streetDirection")} ${getAddressDetail("streetName")} ${getAddressDetail("streetSuffix")}</p>
                                <p><strong>Area:</strong> ${getAddressDetail("area")}</p>
                                <p><strong>City:</strong> ${getAddressDetail("city")}</p>
                                <p><strong>Postal Code:</strong> ${getAddressDetail("zip")}</p>
                                <p><strong>Major Intersection:</strong> ${getAddressDetail("majorIntersection")}</p>
                            </div>
                        `)
                        .addTo(map);
                });

                map.on("mouseenter", "clusters", () => {
                    map.getCanvas().style.cursor = "pointer";
                });
                map.on("mouseleave", "clusters", () => {
                    map.getCanvas().style.cursor = "";
                });
            });

            return () => map.remove();
        }
    }, [mapStyle]);

    console.log("user Loc IN mapppp",userLocation)

    useEffect(() => {
        if (mapInstance.current && userLocation) {
            // Update user location if it changes
            const userLocationSource = mapInstance.current.getSource("userLocation");
            if (userLocationSource) {
                console.log("user location implementing", userLocation);
                userLocationSource.setData({
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [userLocation.longitude, userLocation.latitude],
                            },
                            properties: {},
                        },
                    ],
                });
            } else {
                console.error("User location source not found.");
            }
        }
    }, [userLocation]);

    useEffect(() => {
        if (mapInstance.current) {
            updateSourceData(mapInstance.current, locations);
        }
    }, [locations]);

    // console.log("this is locations before filter",locations)

    function updateSourceData(map, locations) {
        
        const source = map.getSource("properties");
        // console.log("this is source",source)
        // console.log("locations inside function:", locations);
        if (source) {
            // console.log("Updating source data with locations:", locations);
            const geojsonData = {
                type: "FeatureCollection",
                features: locations.map(location => ({
                    type: "Feature",
                    geometry: { type: "Point", coordinates: [location.longitude, location.latitude] },
                    properties: location,
                })),
            };
            source.setData(geojsonData);
        } else {
            console.error("Map source 'properties' not found.");
        }
    }

    return (
        <div className="relative">
            <div className="mt-4" ref={mapContainer} style={{ width: "100%", height: "80vh" }} />

            <div className="absolute top-2 left-2 bg-white p-1 shadow-md flex flex-wrap space-x-1 max-w-full">
                {["topo", "streets", "basic", "bright", "pastel", "hybrid"].map(style => (
                    <button
                        key={style}
                        onClick={() => setMapStyle(style)}
                        className={`text-xs px-1 py-0.5 ${mapStyle === style ? "bg-[#0A2342] text-white" : "bg-white text-[#0A2342] border border-[#0A2342]"}`}
                    >
                        {style.charAt(0).toUpperCase() + style.slice(1)}
                    </button>
                ))}
            </div>
        </div>
    );
});

export default Map;
