// Define a mapping object
const statusTranslation = {
  New: "New",
  Ter: "Terminated",
  Sld: "Sold",
  Lsd: "Leased",
};

const HistoryTable = ({ history }) => {
  return (
    <table className="overflow-x-auto min-w-full border-collapse block border border-[#0A2342] md:table">
      <thead>
        <tr>
          <th className="p-2 border border-[#0A2342]">MLS Number</th>
          <th className="p-2 border border-[#0A2342]">Type</th>
          <th className="p-2 border border-[#0A2342]">List Price</th>
          <th className="p-2 border border-[#0A2342]">List Date</th>
          <th className="p-2 border border-[#0A2342]">Status</th>
          <th className="p-2 border border-[#0A2342]">Sold Price</th>
        </tr>
      </thead>
      <tbody>
        {history.map((item, idx) => (
          <tr key={idx}>
            <td className="p-2 border border-[#0A2342]">{item.mlsNumber}</td>
            <td className="p-2 border border-[#0A2342]">{item.type}</td>
            <td className="p-2 border border-[#0A2342]">
              ${parseFloat(item.listPrice).toLocaleString()}
            </td>
            <td className="p-2 border border-[#0A2342]">
              {new Date(item.listDate).toLocaleDateString()}
            </td>
            <td className="p-2 border border-[#0A2342]">
              {statusTranslation[item.lastStatus] || item.lastStatus}
            </td>
            <td className="p-2 border border-[#0A2342]">
              {item.soldPrice
                ? `$${parseFloat(item.soldPrice).toLocaleString()}`
                : "N/A"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default HistoryTable;
