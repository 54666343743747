import React from "react";
import LandingPageHeader from "../components/landingPage/LandingPageHeader";
import Hero from "../components/landingPage/Hero";
import Feature from "../components/landingPage/Feature";
import AboutUs from "../components/landingPage/AboutUs";
import Team from "../components/landingPage/Team";
import CTA from "../components/landingPage/CTA";
import LandingPageFooter from "../components/landingPage/LandingPageFooter";

export default function LandingPage() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <LandingPageHeader />
      <Hero />
      <Feature />
      <AboutUs />
      <Team />
      <CTA />
      <LandingPageFooter />
    </div>
  );
}
