// FavouritesPage.js
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPropertiesThunk, saveCollectionsThunk } from "../store/thunks/repliersThunk";
import { Link } from "react-router-dom";
import CollectionCard from "../components/favouritesPage/CollectionCard";

const FavouritesPage = () => {
  const dispatch = useDispatch();
  const [collections, setCollections] = useState([]);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [editingCollection, setEditingCollection] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);

  // Fetch properties if needed
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPropertiesThunk()).unwrap();
      // Load existing collections from the user's profile if needed
    };
    fetchData();
  }, [dispatch]);

  // Create a new collection
  const createCollection = async () => {
    if (newCollectionName.length > 25) {
      console.error("Collection name must be less than 25 characters.");
      return;
    }
    const newCollection = {
      id: Date.now(), // Unique ID for the collection
      name: newCollectionName,
      properties: [],
    };
    const updatedCollections = [...collections, newCollection];
    setCollections(updatedCollections);
    setNewCollectionName("");
    await dispatch(saveCollectionsThunk(updatedCollections)).unwrap();
    console.log("Collection created and saved!");
  };

  // Edit a collection
  const editCollection = async (collection) => {
    setEditingCollection(collection);
    const updatedCollections = collections.map((c) => (c.id === collection.id ? collection : c));
    setCollections(updatedCollections);
    await dispatch(saveCollectionsThunk(updatedCollections)).unwrap();
  };

  // Delete a collection
  const confirmDeleteCollection = (collection) => {
    setShowConfirmation(true);
    setCollectionToDelete(collection);
  };

  const deleteCollection = async () => {
    const updatedCollections = collections.filter(c => c.id !== collectionToDelete.id);
    setCollections(updatedCollections);
    setShowConfirmation(false);
    await dispatch(saveCollectionsThunk(updatedCollections)).unwrap();
    console.log("Collection deleted successfully.");
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setCollectionToDelete(null);
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <h1 className="text-2xl font-bold mb-4">My Collections</h1>
      <div className="mb-4">
        <input
          type="text"
          value={newCollectionName}
          onChange={(e) => setNewCollectionName(e.target.value)}
          placeholder="Enter collection name"
          className="border p-2"
        />
        <button onClick={createCollection} className="bg-blue-500 text-white p-2 ml-2">
          Create Collection
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {collections.map((collection) => (
          <CollectionCard 
            key={collection.id} 
            collection={collection} 
            onEdit={editCollection} 
            onDelete={confirmDeleteCollection} 
          />
        ))}
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow">
            <h2 className="text-lg">Are you sure you want to delete this collection?</h2>
            <button onClick={deleteCollection} className="bg-red-500 text-white p-2 mr-2">Confirm</button>
            <button onClick={cancelDelete} className="bg-gray-300 p-2">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FavouritesPage;
