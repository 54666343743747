import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pdfjs } from "react-pdf";
import { fetchPdfs, fetchPdfBlob } from "../store/thunks/pdfsThunk";

import rei_thumbnail from "../assets/The_Ultimate_Wealth_Building_Investment_Checklist_thumbnail.png";
import sellers_thumbnail from "../assets/The_Ultimate_Home_Sellers_Checklist_thumbnail.png";
import buyers_thumbnail from "../assets/The_Ultimate_Home_Buyers_Checklist_thumbnail.png";

// Set up the worker URL for pdfjs
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const thumbnails = [buyers_thumbnail, sellers_thumbnail, rei_thumbnail];

export default function LearnPage() {
  const dispatch = useDispatch();
  const { pdfs, status, error } = useSelector((state) => state.pdfs);

  useEffect(() => {
    dispatch(fetchPdfs());
  }, [dispatch]);

  const handleDownload = (pdf) => {
    dispatch(fetchPdfBlob(pdf.name))
      .unwrap()
      .then((result) => {
        console.log("File Blob:", result.fileBlob);
        console.log("File Type:", result.fileType);

        const blob = new Blob([result.fileBlob], { type: result.fileType });
        const url = URL.createObjectURL(blob);

        // Trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = pdf.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Clean up URL after use
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.error("Failed to download PDF:", err);
      });
  };

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="loader"></div>
          <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
            Loading documents...
          </h2>
        </div>
      </div>
    );
  }

  if (status === "failed") {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="loader"></div>
          <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
            Error: {error}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <section aria-labelledby="details-heading">
          <div className="flex flex-col items-center text-center">
            <h2
              id="details-heading"
              className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Learn more
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-600">
              Explore these resources made by the Synergy Real Estate team to
              learn more.
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 gap-y-16 lg:grid-cols-3 lg:gap-x-8 justify-items-center">
            {pdfs.map((pdf, index) => (
              <div key={index} className="border border-[#0A2342] p-4 w-full">
                <img
                  src={thumbnails[index]}
                  alt={`${pdf.name} thumbnail`}
                  className="mt-2 w-full h-auto"
                />

                <h3 className="mt-2 text-base font-semibold text-[#0A2342] break-words">
                  {pdf.name.replace(/_/g, " ").replace(".pdf", "")}
                </h3>

                {/* Trigger download via Blob */}
                <button
                  onClick={() => handleDownload(pdf)}
                  className="mt-2 inline-block bg-[#0A2342] text-white py-2 px-4 hover:bg-gray-600 border border-[#0A2342]">
                  Download PDF
                </button>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
