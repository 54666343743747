import { createSlice } from "@reduxjs/toolkit";
import {
  submitOnboarding,
  updateOnboarding,
  fetchOnboarding,
} from "../thunks/onboardingThunk";

const initialState = {
  answers: [],
  isCompleted: false,
  error: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateAnswers: (state, action) => {
      state.answers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitOnboarding.pending, (state) => {
        state.error = null;
      })
      .addCase(submitOnboarding.fulfilled, (state, action) => {
        state.answers = action.payload.answers || []; // Ensure answers exists
        state.isCompleted = true;
      })
      .addCase(submitOnboarding.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateOnboarding.fulfilled, (state, action) => {
        state.answers = action.payload.answers || [];
      })
      .addCase(fetchOnboarding.fulfilled, (state, action) => {
        console.log("Fetch onboarding payload:", action.payload); // Log the payload
        state.answers = action.payload.answers || []; // Check and update answers
        state.isCompleted = true; // Mark onboarding as completed if data exists
      })
      .addCase(fetchOnboarding.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { updateAnswers } = onboardingSlice.actions;
export default onboardingSlice.reducer;
