import React, { useState, useEffect } from "react";
import * as Slider from "@radix-ui/react-slider";

const FilterBar = ({
  priceRange,
  setPriceRange,
  bedroomRange,
  setBedroomRange,
  bathroomRange,
  setBathroomRange,
  propertyType,
  setPropertyType,
  listingType,
  setListingType,
  squareFootageRange,
  setSquareFootageRange,
  showSold,
  setShowSold,
  resetFilters,
  applyFilters,
}) => {
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);

  const handlePriceRangeChange = (value) => {
    setPriceRange(value);
    applyFilters();
  };

  const handleBedroomRangeChange = (min, max) => {
    setBedroomRange([min, max]);
    applyFilters();
  };

  const handleBathroomRangeChange = (min, max) => {
    setBathroomRange([min, max]);
    applyFilters();
  };

  const handlePropertyTypeChange = (value) => {
    setPropertyType(value);
    applyFilters();
  };

  const handleSquareFootageRangeChange = (value) => {
    setSquareFootageRange(value);
    applyFilters();
  };

  // useEffect to call applyFilters when propertyType changes
  useEffect(() => {
    applyFilters();
  }, [propertyType]);


  return (
    <div className="fixed z-40 flex flex-col w-full bg-white shadow-md border border-[#0A2342] px-4">
      <div className="flex justify-between items-center md:hidden">
        <button
          className="bg-gray-100 hover:bg-gray-200 border border-gray-300 text-gray-800 font-semibold py-2 px-4"
          onClick={() => setAreFiltersVisible((prev) => !prev)}
        >
          {areFiltersVisible ? "Hide Filters" : "Show Filters"}
        </button>
      </div>

      <div
        className={`flex flex-wrap justify-between ${
          areFiltersVisible ? "" : "hidden md:flex"
        }`}
      >
        {/* Price Range */}
        <div className="flex flex-col w-full md:w-1/4 p-2">
          <label
            htmlFor="price-range"
            className="text-sm font-medium text-gray-700 pb-1"
          >
            Price Range
          </label>
          <Slider.Root
            className="relative flex items-center select-none touch-none w-full h-5"
            value={priceRange}
            onValueChange={handlePriceRangeChange}
            min={0}
            max={3000000}
            step={50000}
          >
            <Slider.Track className="bg-gray-300 relative grow h-[3px]">
              <Slider.Range className="absolute bg-[#0A2342] h-full" />
            </Slider.Track>
            <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
            <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
          </Slider.Root>
          <div className="text-sm text-gray-500 mt-1">
            ${priceRange[0].toLocaleString()} - $
            {priceRange[1].toLocaleString()}
          </div>
        </div>

        {/* Bedrooms */}
        <div className="flex flex-col w-full md:w-[10%] p-2">
          <label className="text-sm font-medium text-gray-700 pb-1">
            Bedrooms
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              placeholder="Min"
              value={bedroomRange[0] === 0 ? '' : bedroomRange[0]}
              onChange={(e) => {
                const min = Math.max(0, parseInt(e.target.value) || 0);
                let max = bedroomRange[1];
                if (min <= max) {
                  setBedroomRange([min, max]);
                } else {
                  setBedroomRange([min, min]);
                }
              }}
              onClick={() => {
                applyFilters();
              }}
              className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]"
            />
            <input
              type="number"
              placeholder="Max"
              value={bedroomRange[1] !== undefined ? bedroomRange[1] : 0}
              onChange={(e) => {
                const max = Math.max(0, parseInt(e.target.value) || 0);
                const min = bedroomRange[0];
                if (max >= min) {
                  setBedroomRange([min, max]);
                } else {
                  setBedroomRange([max, max]);
                }
              }}
              onClick={() => {
                applyFilters();
              }}
              className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]"
            />
          </div>
        </div>

        {/* Bathrooms */}
        <div className="flex flex-col w-full md:w-[10%] p-2">
          <label className="text-sm font-medium text-gray-700 pb-1">
            Bathrooms
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              placeholder="Min"
              value={bathroomRange[0] === 0 ? '' : bathroomRange[0]}
              onChange={(e) => {
                const min = Math.max(0, parseInt(e.target.value) || 0);
                let max = bathroomRange[1];
                if (min <= max) {
                  setBathroomRange([min, max]);
                } else {
                  setBathroomRange([min, min]);
                }
              }}
              onClick={() => {
                applyFilters();
            }}
              className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]"
            />
            <input
              type="number"
              placeholder="Max"
              value={bathroomRange[1] !== undefined ? bathroomRange[1] : 0}
              onChange={(e) => {
                const max = Math.max(0, parseInt(e.target.value) || 0);
                const min = bathroomRange[0];
                if (max >= min) {
                  setBathroomRange([min, max]);
                } else {
                  setBathroomRange([max, max]);
                }
              }}
              onClick={() => {
                applyFilters();
            }}
              className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]"
            />
          </div>
        </div>

        {/* Property Type */}
        <div className="flex flex-col w-full md:w-1/12 p-2">
          <label
            htmlFor="property-type"
            className="text-sm font-medium text-gray-700 pb-1"
          >
            Property Type
          </label>
          <select
            id="property-type"
            value={propertyType}
            onChange={(e) => { 
              const selectedValue = e.target.value;
              setPropertyType(selectedValue);
              console.log("onChange to", selectedValue);
            }}
            className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]"
          >
            <option value="">All</option>
            <option value="ResidentialProperty">Residential</option>
            <option value="CondoProperty">Condo</option>
            <option value="CommercialProperty">Commercial</option>
          </select>
        </div>

        {/* Square Footage */}
        <div className="flex flex-col w-full md:w-2/12 p-2">
          <label
            htmlFor="square-footage"
            className="text-sm font-medium text-gray-700 pb-1"
          >
            Square Footage
          </label>
          <Slider.Root
            className="relative flex items-center select-none touch-none w-full h-5"
            value={squareFootageRange}
            onValueChange={(value) => {
              const validValue = value.map((v) => Math.max(0, v));
              const min = validValue[0];
              const max = validValue[1];
              if (min <= max) {
                handleSquareFootageRangeChange(validValue);
              }
            }}
            min={0}
            max={5000}
            step={50}
          >
            <Slider.Track className="bg-gray-300 relative grow h-[3px]">
              <Slider.Range className="absolute bg-[#0A2342] h-full" />
            </Slider.Track>
            <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
            <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
          </Slider.Root>
          <div className="text-sm text-gray-500 mt-1">
            {squareFootageRange[0]} - {squareFootageRange[1]} sq ft
          </div>
        </div>

        {/* Apply Filters Button */}
        <div className="flex items-center w-full md:w-1/12 p-2">
          <button
            className="w-full bg-[#1E2C53] hover:bg-[#182342] text-white font-semibold py-2 px-4"
            onClick={applyFilters}
          >
            Apply Filters
          </button>
        </div>

        {/* Reset Filters Button */}
        <div className="flex items-center w-full md:w-1/12 p-2">
          <button
            className="w-full bg-slate-200 hover:bg-slate-300 text-black font-semibold py-2 px-4"
            onClick={resetFilters}
          >
            Reset Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
