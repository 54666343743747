import { createSlice } from "@reduxjs/toolkit";
import { fetchLandingPage } from "../thunks/dataThunk";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    landingPageStatus: "idle",
    landingPageError: null,
    apiData: null, // Add apiData to initialState
  },
  reducers: {
    // You can add synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchLandingPage Thunk
      .addCase(fetchLandingPage.pending, (state) => {
        state.landingPageStatus = "loading";
        state.landingPageError = null;
      })
      .addCase(fetchLandingPage.fulfilled, (state, action) => {
        state.landingPageStatus = "succeeded";
        state.apiData = action.payload;
      })
      .addCase(fetchLandingPage.rejected, (state, action) => {
        state.landingPageStatus = "failed";
        state.landingPageError = action.payload || action.error.message;
      });
  },
});

export default dataSlice.reducer;
