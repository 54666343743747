import React from "react";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const HistoryChart = ({ history }) => {
  const chartData = history.map((item) => ({
    listDate: new Date(item.listDate).toLocaleDateString(),
    listPrice: parseFloat(item.listPrice.replace(/,/g, "")),
    soldPrice: item.soldPrice
      ? parseFloat(item.soldPrice.replace(/,/g, ""))
      : null,
  }));

  return (
    <div className="w-full h-full bg-white p-4 shadow-lg">
      <div className="text-lg font-bold mb-4 text-center text-[#0A2342]">
        Property Price History
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 30, left: 35, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="listDate" />
          <YAxis tickFormatter={(value) => `$${value.toLocaleString()}`} />
          <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
          <Area
            type="monotone"
            dataKey="listPrice"
            stroke="#0A2342"
            fillOpacity={0.3}
            fill="#0A2342"
          />
          {chartData.some((item) => item.soldPrice) && (
            <Area
              type="monotone"
              dataKey="soldPrice"
              stroke="#8884d8"
              fillOpacity={0.3}
              fill="#8884d8"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HistoryChart;
